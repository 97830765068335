<template>
    <s-container class="no-bg">
        <div class="s-container-wrap">
        <a-form-model :model="form"  :wrapper-col="wrapperCol">
         <div class="pb10">
             <a-checkbox-group v-model="checkedList" :options="options" @change="onChange" />
            <span class="fr">分隔符支持：逗号(,)，分号(;)，回车，换行，' or '.</span>
        </div>
        <a-form-model-item>
            <a-textarea :auto-size="{minRows: 10}" v-model="form.test"/>
        </a-form-model-item>
        <a-form-model-item class="s-search-btn-group text-right">
            <a-button type="primary" @click="onSubmit">
                检索
            </a-button>
            <a-button style="margin-left: 10px;">
                清空
            </a-button>
        </a-form-model-item>
    </a-form-model>
     </div>
    </s-container>
</template>

<script>
    const options = ['申请号', '公开号'];
    export default {
        name:'NumberSearch',
        data() {
            return {
                checkedList: "公开号",
                options,
                wrapperCol: { span: 24 },
                form:{
                    text:'',
                    type:'AN'
                }
            }
        },
        methods: {
            onSubmit(){

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>